<template>
    <div class="online-container">
        <div class="top-container">
            <!-- 新建 -->
            <div>
                <el-button type="primary" size='small' icon="el-icon-finished"  @click="handleClick('myDocument')">选择文件</el-button>
                <el-button type="primary" size='small' icon="el-icon-plus"  @click="handleClick('create')">新建</el-button>
                <el-button type="primary" size='small' icon="el-icon-upload2" @click="handleClick('upload')">上传</el-button>
            </div>
            <el-form :inline="true" :model="query" class="demo-form-inline" size='small'>
                <el-form-item label="文件名">
                    <el-input v-model="query.name" placeholder="请输入文件名"  clearable  @keyup.enter.native="pageChanged(1)"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="pageChanged(1)">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
       
        <!-- 列表 -->
        <div>
            <el-table
                v-if='viewer.list.length != 0'
                size="small"
                @row-click='handelRowClick'
                style="width: 100%;"
                :data="viewer.list">
                <el-table-column
                    prop="fileName"
                    label="文件名">
                     <template slot-scope="scope">
                       <div class="name-box">{{scope.row.fileName}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orginFileType"
                    label="文件类型"
                    align='center'
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="createDate"
                    align='center'
                    width="200"
                    label="创建时间">
                </el-table-column>
                 <el-table-column
                    label="操作"
                    width="200"
                    align="center">
                    <template slot-scope="scope">
                        <a class="download-style" :href="downloadHref(scope.row)" download="/images/logo.png" @click.stop>下载</a>
                        <el-button type="text" @click.stop="handleClick('save',scope.row)">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="no-data-box" v-if='viewer.list.length == 0'>
                <img src="@/assets/no-file.svg" alt="">
                <span>暂无数据</span>
            </div>
            <el-pagination
                v-if='viewer.list.length != 0'
                class="pagebox"
                @current-change="pageChanged"
                :page-size="query.pageSize"
                layout="total,  prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>

        <!-- 上传组件 -->
        <UploadFile ref='UploadFile' @pageChanged='pageChanged(1)'></UploadFile>

        <!-- 选择我的文档 -->
        <SelectMyDocument ref='SelectMyDocument' @SelectMyDocument='SelectMyDocument'></SelectMyDocument>

        <!-- onlyOffice -->
        <OnlyOffice ref='onlyOffice' @createOver='pageChanged(1)'></OnlyOffice>

        <!-- 创建文档 -->
        <CreateFile ref='CreateFile' @openOnlyOffice='openOnlyOffice'></CreateFile>
    </div>
</template>
<script>
import UploadFile from './components/upload.vue'
import SelectMyDocument from './components/SelectMyDocument.vue'
import OnlyOffice from '../liankong/components/OnlyOffice.vue'
import CreateFile from './components/createFile.vue'
import { teamWorkAPI } from 'liankong-ui-api'
import { Store } from '@/utils/LocalCache.js'
export default {
    components:{
        UploadFile,
        SelectMyDocument,
        OnlyOffice,
        CreateFile
    },
    data(){
        return {
            query:{
                pageNum:1,
                pageSize:10,
                bussinessType:9
            },
            vkey:'online'
        }
    },
    computed:{
        viewer(){
            return this.$store.state.team.views[this.vkey];
        },
        total(){
            return this.$store.state.team.views[this.vkey].paging.total;
        }
    },
    methods:{
        downloadHref(row){
            return this.$options.filters['baseUrl'](`/api/v1/document/tool/download/origin/${row.id}?access_token=${Store.get('access_token')}`)
        },
        pageChanged(num){
            this.query.pageNum = num;
            this.onLoad()
        },
        // 选择我的文档，获取返回的文档ID，并打开onlyOffice
        SelectMyDocument(id,suffix,type){
            if(id  != null ){
                teamWorkAPI.getOnlyOfficeFromDocument(id,type).then(res => {
                    if(res.code == 0){
                        res.data.fileType = suffix;
                        this.openOnlyOffice(res.data)
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
        },
        // 行点击打开onlyOffice
        handelRowClick(row){
            this.SelectMyDocument(row.id,row.orginFileType,1)
        },
        openOnlyOffice(data){
            this.onLoad()
            if(Store.get("myPreference")){
                if(Store.get("myPreference").reader_model == '1'){
                    const routeData = this.$router.resolve({
                        path:'/onlyOffice',
                        query:data
                    })
                    window.open(routeData.href,'_blank');
                }else{
                    this.$refs['onlyOffice'].init(data)
                }
            }else{
                this.$refs['onlyOffice'].init(data)
            }
        },
        // 统一事件处理
        handleClick(type,row){
            switch(type){
                // 上传
                case 'upload':
                    this.$refs['UploadFile'].dialogVisible = true;
                    break;
                // 选择我的文件
                case 'myDocument':
                    this.$refs.SelectMyDocument.show('file');
                    break;
                // 新建文件
                case 'create':
                    this.$refs.CreateFile.dialogVisible = true;
                    break;
                // 另存为
                case 'save':
                    this.$refs.SelectMyDocument.show('folder',row.id);
                    break;
            }
        },
        onLoad(){
            this.$store.dispatch('team/loadByQuery',{
                viewerKey:this.vkey,
                query:this.query,
                reset:true,
                interfaceName:'getOnlineList'
            })
        }
    },
    mounted(){
        this.onLoad()
    }
}
</script>
<style lang="less" scoped>
.online-container{
    padding: 20px;
    .el-table thead {
        color: #909399;
        font-weight: 500;
    }
    /deep/.el-table__header-wrapper {
        .has-gutter {
        tr th{
            border:none;
            background: #F2F2F2;
            color: #666;
        }
        }
    }
    .el-table__body-wrapper {
        tr td {
        border:none;
        padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
    .top-container{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
            margin-bottom: 0;
        }
    }
    .pagebox{
        margin-top: 10px;
    }
    .name-box{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .download-style{
        color: #409EFF;
        margin-right: 10px;
    }
}
</style>