<template>
    <el-dialog
        title="创建文档"
        :close-on-click-modal="false" 
        @close='closeDialog'
        :visible.sync="dialogVisible"
        width="500px">
        <el-form ref="form" size='small' :model="form" :rules="rules" label-width="100px">
            <el-form-item label="文档名称：" prop="documentName">
                <el-input v-model="form.documentName" placeholder="请输入文档名称"></el-input>
            </el-form-item>
            <el-form-item label="文档类型：" prop="type">
                <el-select v-model="form.type" placeholder="请选择文档类型">
                    <el-option label="docx" value="docx"></el-option>
                    <el-option label="pptx" value="pptx"></el-option>
                    <el-option label="xlsx" value="xlsx"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeDialog">取 消</el-button>
            <el-button size="small" type='primary' @click='onSubmit' >确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { teamWorkAPI } from 'liankong-ui-api'
export default {
    data(){
        return {
            dialogVisible:false,
            form:{
                documentName:null,
                type:null
            },
            rules: {
                documentName: [
                    { required: true, message: '请输入文档名称', trigger: 'blur' },
                    { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
                ],
                type:[ { required: true, message: '请选择文档类型', trigger: 'change' }]
            }
        }
    },
    methods: {
        closeDialog(){
            this.form ={
                documentName:null,
                type:null
            }
            this.dialogVisible = false;
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    teamWorkAPI.createNewFile(this.form).then(res => {
                        if(res.code == 0){
                            this.$emit('openOnlyOffice',res.data)
                            this.closeDialog()
                        }else{
                            this.$message.error(res.message)
                        }
                    })
                } else {
                    return false;
                }
            });
           
        },
    }
}
</script>
<style lang='less' scoped>
.el-select{
    width: 100%;
}
</style>
