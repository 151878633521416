<template>
    <div>
        <el-dialog
        :title="title"
        :append-to-body="true"
        @close="closeLoding"
		:close-on-click-modal='false'
		:destroy-on-close='true'
		class="readFile-loading"
		:show-close="false" 
		:close-on-press-escape="false"
		:custom-class="addClassScreen?'readFile':''"
		:visible.sync="dialogVisible" 
 		top="5vh" >
			<span slot="title" class="dialog-title">
				<div class="header">
				<span class="read">{{title}}</span>
				<p class="btn">
					<span @click="requestFullscreen">
					<img :src="addClassScreen?require('../../../assets/shrink.png'):require('../../../assets/blowup.png')" alt="">
					</span>
					<!-- <i class="el-icon-full-screen" @click="requestFullscreen"></i> -->
					<i class="el-icon-close" @click="closeLoding"></i>
				</p>
				</div>
			</span>
			<div class="box">
				<div id="placeholder" class="nav" style="width: 100%; height: 100%"></div>
			</div>
        </el-dialog>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        title:"在线编辑",
		addClassScreen:false,
		fileTypesDoc:['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'epub', 'fodt', 'htm', 'html', 'mht', 'odt', 'ott', 'pdf', 'rtf', 'txt', 'djvu', 'xps'],
		fileTypesCsv:['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'],
		fileTypesPPt:['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
      };
    },
	mounted() {
      let that = this;
      window.addEventListener('resize',(e)=>{
        if(!this.checkFull()){
          that.exitFullscreen()
        }
      })
    },
    methods: {
        closeLoding(){
          this.$emit('createOver');
          if(this.addClassScreen){
            this.exitFullscreen()
          }
          this.addClassScreen=false
          this.dialogVisible = false;
        },
		// key文档标识 url文件地址 suffix文件类型 title文档名称 callbackUrl保存时的回调地址
        init(rows){
			if(rows.fileType == 'pdf'){
				this.title = 'PDF阅读'
			}else{
				this.title = '在线编辑'
			}
			this.dialogVisible = true;
            this.$nextTick(()=>{
				let docType = this.handleDocType(rows.fileType);
            	this.initDoc(rows.key,rows.url,rows.fileType,rows.title,rows.mode,rows.callBackUrl,docType,rows.userInfo);
			})
        },
		handleDocType(fileType) {
			let docType = '';
			if (this.fileTypesDoc.includes(fileType)) {
				docType = 'text'
			}
			if (this.fileTypesCsv.includes(fileType)) {
				docType = 'spreadsheet'
			}
			if (this.fileTypesPPt.includes(fileType)) {
				docType = 'presentation'
			}
			return docType;
		},
		initDoc(key, url, fileType, title, mode, callbackUrl, docType, userInfo){
			let config = {
				"document": {
					"width": "100%", //打开窗口宽度
					"height": "100%", //打开窗口高度
					"fileType": fileType, //文档类型
					"key": key, //定义用于服务识别文档的唯一文档标识符。每次编辑和保存文档时，都必须重新生成密钥。长度限制为128个符号。
					"title": title, //为查看或编辑的文档定义所需的文件名，该文件名也将在下载文档时用作文件名。长度限制为128个符号。
					"url": url, //定义存储原始查看或编辑的文档的绝对URL
					//文档权限参数
					"permissions": {
						"edit": true, //（文件是否可以编辑，false时文件不可编辑）
						"fillForms": true, //定义是否能在文档中填充表单
						"print": true, //定义文档是否能打印
						"review": false, //第一是否显示审阅文档菜单
						"comment": true, //定义是否可以注释文档。如果注释权限设置为“ true”，则文档侧栏将包含“注释”菜单选项；只有将mode参数设置为edit时才生效，默认值与edit参数的值一致。
						"copy": true, //是否允许您将内容复制到剪贴板。默认值为true。
						"download": true, //定义是否可以下载文档或仅在线查看或编辑文档。如果下载权限设置为“false”下载为菜单选项将没有。默认值为true。
						"modifyContentControl": true, //定义是否可以更改内容控件设置。仅当mode参数设置为edit时，内容控件修改才可用于文档编辑器。默认值为true。
						"modifyFilter": true, //定义过滤器是否可以全局应用（true）影响所有其他用户，或局部应用（false），即仅适用于当前用户。如果将mode参数设置为edit，则过滤器修改仅对电子表格编辑器可用。默认值为true。
					}
				},
				"documentType": docType,
				"editorConfig": { //编辑配置
					"mode": mode,//文档操作模式 view 视图模式不可编辑  edit 编辑模式可编辑文档
					"callbackUrl": callbackUrl, //保存文件时的回调地址
					"lang": "zh-CN", //语言环境
					"customization": { //定制部分允许自定义编辑器界面，使其看起来像您的其他产品，并更改是否存在其他按钮，链接，更改徽标和编辑者所有者详细信息。
						"autosave": false, //定义是启用还是禁用“自动保存”菜单选项。请注意，如果您在菜单中更改此选项，它将被保存到浏览器的localStorage中。默认值为true。
						"hideRightMenu": true, 
						"forcesave":true
					},
					"user": { //用户信息
						"id": userInfo.id, //用户ID
						"name": userInfo.name //用户全名称
					},
				},
			};
			new DocsAPI.DocEditor("placeholder", config);
		},
		checkFull() {
			//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
			//火狐浏览器
			let isFull = document.mozFullScreen ||
				document.fullScreen ||
				//谷歌浏览器及Webkit内核浏览器
				document.webkitIsFullScreen ||
				document.webkitRequestFullScreen ||
				document.mozRequestFullScreen ||
				document.msFullscreenEnabled
			if (isFull === undefined) {
				isFull = false
			}
			return isFull;
		},
		requestFullscreen(){
			this.addClassScreen=!this.addClassScreen
			if(this.addClassScreen){
			const element = document.documentElement
				if (element.requestFullscreen) {
				element.requestFullscreen()
				} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen()
				} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen()
				} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen()
				}
			} else {
			if (document.fullscreenElement !== null) {
				document.exitFullscreen()
				} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
				} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
				} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
				}
			}
		},
		exitFullscreen(){
			this.addClassScreen=false
			if (document.fullscreenElement !== null) {
			document.exitFullscreen()
			} else if (document.msExitFullscreen) {
			document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
			document.webkitCancelFullScreen()
			}
		},
    }
  };
</script>
<style lang="less" scoped>
 /deep/ .el-dialog {
    width: 65%;
	height: 90%;
	display: flex;
	flex-direction: column;
	.box {
		height: 80vh;
	}
}
/deep/ .readFile {
	width: 100%;
	height: 100%;
	margin-top: 0px !important;
	.box {
		height: 100vh;
	}
}

/deep/.el-dialog__body{
	display: block !important;
	padding: 0px 20px;
	flex: 1
}
/deep/ .el-dialog__header{
        padding: 10px 20px 10px;
    }
 /deep/ .dialog-title {
      display: flex;
      justify-content: space-between;
      .header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .read {
          line-height:24px;
          color:#303133;
          font-size:18px
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          i {
            font-size: 20px;
            padding: 0 5px;
            box-sizing: border-box;
          }
        }
        
      }
    }
</style>