<template>
    <el-dialog
        title="上传文件"
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible"
        width="500px">
        <div style="text-align:center">
            <el-upload
                class="upload-demo"
                drag
                ref="upload"
                action="none"
                :limit='1'
                :auto-upload="false"
                :on-change="onUploadChanged"
                :show-file-list="true">
                    <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeDialog">取 消</el-button>
            <el-button size="small" type='primary' @click='onSubmit' >上传</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    data(){
        return {
            dialogVisible:false,
            selectedFile:null,
            accept:['docx','wps','doc','xlsx','pptx','xls','ppt','pdf','rtf']
        }
    },
    methods: {
        closeDialog(){
            this.selectedFile = null;
            this.dialogVisible = false;
        },
        onSubmit(){
            //获取中文件后缀
            const fileSuffix = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf(".") + 1);
            if(this.accept.indexOf(fileSuffix.toLowerCase()) ==-1){
                const List = this.accept.join('、');
                this.$message.error(`目前格式只支持：${List}`);
                this.$refs.upload.clearFiles();
                return
            }
            let uploadFormData = new FormData()
            uploadFormData.append("file",  this.selectedFile.raw)
            uploadFormData.append("bussinessType", 9);

            this.$store.dispatch('tools/documentUploadEvent', {
                query: uploadFormData
            }).then(res=>{
                if(res.code == 0) {
                    this.$message.success('上传成功');
                    this.$emit('pageChanged')
                } else {
                    this.$message.error(res.message);
                }
                this.closeDialog()
                this.$refs.upload.clearFiles();
            })
           
        },
        onUploadChanged(file){
            this.selectedFile = file;
        }
    }
}
</script>
<style lang='less' scoped>

</style>
